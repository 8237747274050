import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SectionHeader } from "../utils/commonComponents"
import ContactCard from "../components/contactCard"

const ContactsPage = () => (
  <Layout>
    <SEO title="contacts" />
    <SectionHeader header="CONTACT" />
    <ContactCard />
  </Layout>
)

export default ContactsPage
