import React, { useState } from "react"
import "./contactCard.css"

const ContactCard = inputProps => {
  const [view, toggleView] = useState("front")
  return (
    <div class="container">
      <div
        class={view === "front" ? "card" : "card flipped"}
        onClick={() => {
          if (view === "front") {
            toggleView("back")
          } else {
            toggleView("front")
          }
        }}
      >
        <div
          data-sal="slide-right"
          data-sal-delay="1000"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          <figure class="front">
            <img
              src="https://images.unsplash.com/photo-1532289608746-8aaaa40620f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=450&q=60"
              alt="front"
            />
            <div class="caption">
              <h2>
                Devon <span>Yu</span>
              </h2>
              <p>Software Engineer</p>
            </div>
          </figure>

          <figure class="back">
            <img
              src="https://images.unsplash.com/photo-1556764420-028655a23f0c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=450&q=60"
              alt="back"
            />
            <div class="caption">
              <dl>
                <dt>Linkedin</dt>
                <dd>linkedin.com/in/devonyu</dd>
                <dt>Github</dt>
                <dd>github.com/devonyu</dd>
                <dt>Email</dt>
                <dd>devonyu415@gmail.com</dd>
                <dt>Web</dt>
                <dd>devonyu.com</dd>
              </dl>
            </div>
          </figure>
        </div>
      </div>
    </div>
  )
}

export default ContactCard
